import * as React from "react"
import { graphql } from 'gatsby'
import "../styles/main.css"
import Header from "../components/header.js"
import Footer from "../components/footer.js"
import BasicBanner from "../components/basicBanner.js"
import Profits from "../components/contactProfits.js"
import ContactForm from "../components/contactForm.js"

const ContactPage = (props) => {

  return (
    <main>
      <Header page={3}></Header>
      <BasicBanner text={props.props.bannerText} />
      <Profits profits={props.props.profits}></Profits>
      <ContactForm></ContactForm>
      <Footer></Footer>
    </main>
  )
}

const ContactPageExport = ({ data }) => {
  const params = data.allMdx.nodes[0].frontmatter
  return (
    <ContactPage props={params}></ContactPage>
  )
}

export default ContactPageExport

export const pageQuery = graphql`
query ContactPageQuery {
    allMdx(filter: {frontmatter: {templateKey: {eq: "contact"}}}) {
      nodes {
        frontmatter {
          bannerText
          profits {
              text
          }
        }
      }
    }
  }
  

`