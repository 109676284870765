import React from "react"
import "../styles/contactForm.css"
import { navigate } from "gatsby";

export default function ContactForm(props) {

    function encode(data) {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    const handleSubmit = (event) => {
        console.log("i happen")
        event.preventDefault();
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
                "form-name": event.target.getAttribute("name"),
                // ...name
            })
        }).then(() => navigate("/contact"));
    }

    return (
        <div className="contactForm">
            <div className="contactFormIn">
                <div className="contactFormTitle">Jätä yhteystietosi, niin soitamme sinulle.</div>
                <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact" id="contact">
                    <div className="formFieldsSmall">
                        <input className="formFieldSmall" type="hidden" name="bot-field" />
                        <input className="formFieldSmall" type="hidden" name="form-name" value="contact" />
                        <input className="formFieldSmall" type="text" name="name" placeholder="Nimi" />
                        <input className="formFieldSmall" type="text" name="surname" placeholder="Sukunimi" />
                        <input className="formFieldSmall" type="text" name="phone" placeholder="Puh. numero" />
                        <input className="formFieldSmall" type="text" name="email" placeholder="Email" />
                        <input className="formFieldSmall" type="text" name="address" placeholder="Asunnon osoite" />
                        <input className="formFieldSmall" type="text" name="size" placeholder="Asunnon koko" />
                    </div>
                    <textarea form="contact" className="formFieldBig" name="description" placeholder="Asunnon kuvaus" />
                    <div className="contactBtnWrap">
                        <button className="formBtn" type="submit" onSubmit={handleSubmit}>Lähetä</button>
                    </div>
                </form>
            </div>
        </div>
    )
}