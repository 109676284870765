import React, { useRef } from "react"
import { StaticQuery, graphql } from "gatsby"
import "../styles/header.css"

export default function Header(props) {
    let headerLinksRef = useRef(null);
    let isDn = true;
    function renderLinks(linksArray) {
        return linksArray.map((item, i) => {
            if (i === props.page) {
                return (<a key={i} href={item.link} className="headerLink headerLink__active">
                    <div>{item.title}</div>
                    <div className="underLine"></div>
                </a>)
            } else {
                return (<a key={i} href={item.link} className="headerLink">{item.title}</a>)
            }
        })
    }
    return (
        <StaticQuery
            query={graphql`
                query HeaderQuery {
                    allMdx(filter: {frontmatter: {templateKey: {eq: "settings"}}}) {
                    nodes {
                        frontmatter {
                        logo
                        city
                        email
                        phone
                        header {
                            link
                            title
                        }
                        }
                    }
                    }
                }
      `}
            render={data => {
                let header = data.allMdx.nodes[0].frontmatter
                // console.log(header)
                return (
                    <header className={"header"}>
                        <div className="headerContainer">
                            <a href="/" className="headerImageContainer">
                                <img className="headerImage" src={header.logo} alt=""></img>
                            </a>
                            <div className="headerDropdownBtn" onClick={() => {
                                headerLinksRef.current.style.display = isDn === true ? "flex" : "none";
                                isDn = !isDn;
                            }}></div>
                            <div ref={headerLinksRef} className="headerLinks">
                                {renderLinks(header.header)}
                            </div>
                        </div>
                    </header>
                )
            }}
        />
    )
}