import React from "react"
import "../styles/profits.css"

export default function Profits(props) {

    console.log(props)

    return (
        <div className="profits">
            <div className="profitsIn">
                <div className="profitsTitle">Miten maksamme asuntokehittäjinä paremman hinnan:</div>
                <div className="profitsList">
                    {renderProfits(props.profits)}
                </div>
            </div>
        </div>
    )

    function renderProfits(profits) {
        return profits.map((item, i) => {
            return (
                <div className="profitsItem" key={i}>
                    <div className="profitsItemNumber">{i + 1}.</div>
                    <div className="profitsItemText">{item.text}.</div>
                </div>
            )
        })
    }
}