import React from "react"
import "../styles/footer.css"
import { StaticQuery, graphql } from "gatsby"
import map from "../images/map.png"
import Logo from "../images/logo.js"
import Instagram from "../images/instagram.js"
import Facebook from "../images/facebook.js"
import Linkedin from "../images/linkedin.js"

export default function Footer() {

    return (
        <StaticQuery
            query={graphql`
                query FooterQuery {
                    allMdx(filter: {frontmatter: {templateKey: {eq: "settings"}}}) {
                    nodes {
                        frontmatter {
                        logo
                        city
                        email
                        phone
                        linkedin
                        instagram
                        facebook
                        header {
                            link
                            title
                        }
                        }
                    }
                    }
                }
      `}
            render={data => {
                let footer = data.allMdx.nodes[0].frontmatter
                return (
                    <footer className="footer">
                        <div className="footerFade"></div>
                        <div className="footerWrap">

                            <a href="/" className="footerImageContainer footerResLogo">
                                <Logo color="white"></Logo>
                            </a>
                            <div className="footerLeft">
                                <a href="/" className="footerImageContainer footerLeftLogo">
                                    <Logo color="white"></Logo>
                                </a>

                                <div className="footerCity">{footer.city}</div>

                                <div className="footerContactLinks">
                                    <a className="footerContactLink" href={"mailto:" + footer.email.trim()}>{footer.email.trim()}</a>
                                    <a className="footerContactLink" href={"tel:" + footer.phone.trim()}>{footer.phone.trim()}</a>
                                </div>

                                <div className="footerSome">
                                    <a target="_blank" href={"https://" + footer.linkedin} className="footerSomeItem"><Linkedin color="white" /></a>
                                    <a target="_blank" href={"https://" + footer.facebook} className="footerSomeItem"><Facebook color="white" /></a>
                                    <a target="_blank" href={"https://" + footer.instagram} className="footerSomeItem"><Instagram color="white" /></a>
                                </div>
                            </div>
                            <div className="footerRight">
                                {renderLinks(footer.header)}
                            </div>
                        </div>
                    </footer>
                )
            }}
        />
    )

    function renderLinks(linksArray) {
        return linksArray.map((item, i) => (
            <a key={i} href={item.link} className="footerRightLink">{item.title}</a>
        ));
    }
}