import * as React from "react"

export default function Logo(props) {
    return (
        <svg width="362" height="67" viewBox="0 0 362 67" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_708_244)">
                <path d="M41.723 40.844C40.5942 39.5507 39.2157 38.531 37.6816 37.8546C34.3895 36.4524 30.7145 36.4524 27.4225 37.8546C25.8931 38.5337 24.5159 39.5477 23.3811 40.8303C22.2141 42.1712 21.3112 43.746 20.7257 45.4622C20.0685 47.4249 19.7462 49.496 19.7736 51.5786C19.7486 53.67 20.0708 55.7497 20.7257 57.7224C21.288 59.4638 22.1683 61.0705 23.3163 62.4506C24.4463 63.7627 25.826 64.8051 27.3642 65.5088C29.0003 66.2301 30.7545 66.6015 32.5261 66.6015C34.2978 66.6015 36.052 66.2301 37.688 65.5088C39.2234 64.8007 40.599 63.7534 41.723 62.4369C42.8846 61.0563 43.7802 59.4477 44.359 57.7018C45.018 55.7461 45.349 53.683 45.337 51.6061C45.3628 49.5326 45.0404 47.4708 44.3849 45.5172C43.7987 43.7875 42.8938 42.1989 41.723 40.844ZM42.4549 57.0833C41.9827 58.583 41.2242 59.9633 40.2269 61.138C39.2811 62.2292 38.1201 63.0857 36.8266 63.6464C34.0641 64.7829 31.0011 64.7829 28.2386 63.6464C26.9472 63.089 25.7898 62.2319 24.8513 61.138C23.874 59.9561 23.1316 58.577 22.6687 57.0833C22.1166 55.3107 21.8494 53.4521 21.8785 51.5855C21.8419 49.7385 22.1093 47.8989 22.6687 46.1495C23.1496 44.6736 23.9125 43.319 24.9096 42.1704C25.8583 41.0961 27.0195 40.2582 28.3098 39.717C31.0682 38.6083 34.1136 38.6083 36.872 39.717C38.149 40.2557 39.2972 41.089 40.2334 42.1567C41.2185 43.3138 41.9701 44.6726 42.4419 46.1495C43.0058 47.9052 43.2776 49.7516 43.245 51.6061C43.2719 53.4659 43.0048 55.3173 42.4549 57.0833Z" fill={props.color} />
                <path d="M71.1007 37.3255H68.3546L52.992 53.1318V37.3255H51.0166V66.0724H52.992V56.0457L58.5101 50.376L69.3196 66.0724H71.9686L59.9479 48.8298L71.1007 37.3255Z" fill={props.color} />
                <path d="M79.5141 37.3255H77.5322V66.0724H79.5141V37.3255Z" fill={props.color} />
                <path d="M105.654 56.4579C105.472 55.9606 105.256 55.4782 105.006 55.0147C104.762 54.576 104.478 54.164 104.158 53.7846C103.77 53.3436 103.348 52.9389 102.895 52.5751C103.764 52.3915 104.597 52.0497 105.356 51.5648C106.029 51.1261 106.616 50.5546 107.085 49.8811C107.546 49.2091 107.891 48.455 108.102 47.6545C108.328 46.7768 108.439 45.8703 108.432 44.9605C108.479 43.6513 108.226 42.3495 107.694 41.167C107.233 40.2205 106.545 39.4194 105.699 38.8442C104.829 38.27 103.864 37.8737 102.856 37.6759C101.768 37.4533 100.661 37.3429 99.553 37.346H87.3574V66.0792H89.3328V53.1042H98.8082C99.477 53.0902 100.141 53.2333 100.751 53.5235C101.305 53.8043 101.806 54.191 102.228 54.6643C102.672 55.174 103.049 55.745 103.348 56.3617C103.681 57.0293 103.975 57.7178 104.229 58.4234L106.923 66.0792H109.106L106.308 58.2585C106.062 57.5713 105.842 56.9665 105.654 56.4579ZM102.176 50.7127C101.042 50.9381 99.8903 51.044 98.7369 51.0288H89.3328V39.4283H99.3069C101.643 39.4283 103.402 39.8521 104.585 40.6997C105.764 41.5519 106.36 42.9607 106.36 44.9399C106.385 45.9078 106.233 46.8716 105.913 47.7782C105.629 48.5272 105.154 49.177 104.54 49.6543C103.83 50.1746 103.025 50.5283 102.176 50.6921V50.7127Z" fill={props.color} />
                <path d="M122.707 37.3049C121.818 39.7697 120.941 42.1934 120.077 44.5758C119.214 46.9582 118.35 49.3154 117.487 51.6474C116.623 54.0023 115.76 56.3687 114.896 58.7465L112.267 66.0793H114.372C114.883 64.6086 115.421 63.0692 115.978 61.4473C116.535 59.8254 117.059 58.2929 117.539 56.8429H130.874L134.19 66.0793H136.561L125.505 37.3049H122.707ZM118.057 55.1729L124.022 39.1879L130.304 55.1729H118.057Z" fill={props.color} />
                <path d="M159.695 63.5297L143.297 37.3255H140.57V66.0724H142.552V39.8202L159.08 66.0724H161.677V37.3255H159.695V63.5297Z" fill={props.color} />
                <path d="M199.624 37.3049C198.735 39.7697 197.856 42.1934 196.988 44.5758C196.12 46.9582 195.257 49.3245 194.397 51.6749C193.534 54.0298 192.67 56.3962 191.807 58.774C190.943 61.1518 190.069 63.5869 189.184 66.0793H191.289C191.8 64.6086 192.338 63.0692 192.895 61.4473C193.452 59.8254 193.976 58.2929 194.456 56.8429H207.791L211.139 66.0793H213.51L202.422 37.3049H199.624ZM194.974 55.1729L200.939 39.1879L207.253 55.1798L194.974 55.1729Z" fill={props.color} />
                <path d="M263.684 37.3255H260.932L245.569 53.1318V37.3255H243.594V66.0724H245.569V56.0457L251.087 50.376L261.897 66.0724H264.546L252.525 48.8298L263.684 37.3255Z" fill={props.color} />
                <path d="M289.896 40.844C288.763 39.5526 287.383 38.5334 285.848 37.8546C282.558 36.4528 278.885 36.4528 275.595 37.8546C274.064 38.5336 272.685 39.5475 271.547 40.8303C270.377 42.1791 269.474 43.7636 268.892 45.4897C268.229 47.4511 267.905 49.5228 267.933 51.6061C267.909 53.6879 268.229 55.758 268.879 57.7225C269.45 59.4685 270.344 61.076 271.508 62.4506C272.64 63.7613 274.019 64.8035 275.556 65.5088C277.198 66.2347 278.959 66.6084 280.738 66.6084C282.516 66.6084 284.277 66.2347 285.919 65.5088C287.452 64.7987 288.825 63.7517 289.948 62.4369C291.111 61.058 292.007 59.4489 292.584 57.7018C293.229 55.7436 293.547 53.6806 293.523 51.6061C293.549 49.5326 293.226 47.4708 292.571 45.5172C291.978 43.7875 291.069 42.1992 289.896 40.844ZM290.621 57.0833C290.152 58.5842 289.393 59.965 288.393 61.138C287.444 62.2347 286.276 63.0918 284.973 63.6464C282.213 64.7825 279.152 64.7825 276.392 63.6464C275.099 63.0891 273.939 62.2321 272.998 61.138C272.009 59.9604 271.257 58.5806 270.79 57.0833C270.233 55.3118 269.963 53.4527 269.993 51.5855C269.964 49.7402 270.236 47.9036 270.796 46.1563C271.277 44.6805 272.04 43.3259 273.037 42.1773C273.987 41.1045 275.148 40.2669 276.437 39.7239C279.196 38.6151 282.241 38.6151 284.999 39.7239C286.277 40.2643 287.427 41.0973 288.367 42.1635C289.364 43.3153 290.126 44.672 290.608 46.1495C291.172 47.9052 291.444 49.7516 291.411 51.6061C291.441 53.4661 291.174 55.3179 290.621 57.0833Z" fill={props.color} />
                <path d="M307.37 37.3529H299.183V66.0792H307.37C316.497 66.0792 321.059 61.2136 321.055 51.4824C321.055 46.7405 319.917 43.2012 317.642 40.8647C315.366 38.5281 311.942 37.3575 307.37 37.3529ZM318.95 51.4755C318.971 53.2518 318.789 55.0242 318.406 56.7535C318.087 58.2029 317.442 59.5491 316.528 60.6776C315.557 61.8104 314.325 62.6559 312.953 63.131C311.134 63.7459 309.232 64.0316 307.324 63.9763H301.165V39.4283H307.324C309.236 39.3757 311.143 39.6495 312.972 40.2393C314.335 40.6951 315.566 41.5137 316.547 42.6171C317.457 43.6976 318.101 45.0011 318.419 46.4106C318.793 48.0691 318.971 49.7704 318.95 51.4755Z" fill={props.color} />
                <path d="M329.785 37.3255H327.803V66.0724H329.785V37.3255Z" fill={props.color} />
                <path d="M176.878 11.4031H174.935V27.0102H176.878V11.4031Z" fill={props.color} />
                <path d="M183.821 12.7982H181.878V24.2406H183.821V12.7982Z" fill={props.color} />
                <path d="M325.206 13.6296H323.263V25.072H325.206V13.6296Z" fill={props.color} />
                <path d="M13.8667 8.13184L79.417 14.6949L79.5983 12.6332L11.9237 5.87085V37.3942V57.3239C11.9506 58.5506 11.8353 59.7762 11.5804 60.9731C11.4091 61.7745 11.0605 62.5213 10.5636 63.1516C10.1342 63.6677 9.57101 64.0382 8.94441 64.2168C8.24465 64.4099 7.52361 64.5024 6.80063 64.4917C6.15942 64.5358 5.51664 64.4362 4.91408 64.1994C4.31152 63.9626 3.76268 63.5939 3.30324 63.1172C2.50013 62.2009 2.06835 60.7967 2.00791 58.9045L0.000140717 58.9595C-0.0160545 60.0344 0.143925 61.104 0.472937 62.1208C0.759123 63.0164 1.22291 63.8366 1.83304 64.5261C2.43226 65.2067 3.16678 65.7373 3.98329 66.0792C4.881 66.4498 5.83743 66.6341 6.80063 66.6221C7.85892 66.6365 8.9113 66.4527 9.90943 66.0792C10.7746 65.7484 11.5398 65.1763 12.1244 64.423C12.764 63.5588 13.2192 62.5576 13.4586 61.4885C13.782 60.0483 13.9322 58.5705 13.9055 57.0902V37.3942L13.8667 8.13184Z" fill={props.color} />
                <path d="M185.11 37.3255H166.703V39.4353H175.725V66.0724H177.707V39.4353H185.11V39.4284H187.182V37.3255H185.11Z" fill={props.color} />
                <path d="M362 39.4353L329.112 0.10498L219.617 35.9647V20.7907L225.343 23.2372L226.068 21.3267L176.755 0.235555L78.9248 24.3436L79.3587 26.3504L176.599 2.39346L217.629 19.9385V38.8236L328.477 2.52404L357.577 37.3255H352.674H335.452V39.4284H344.824V66.0724H346.806V39.4284H352.674V39.4353H362Z" fill={props.color} />
            </g>
            <defs>
                <clipPath id="clip0_708_244">
                    <rect width="362" height="66.4966" fill={props.color} transform="translate(0 0.10498)" />
                </clipPath>
            </defs>
        </svg>


    )
}